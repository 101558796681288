<template>
  <v-app>
    <!-- App Bar -->
    <v-app-bar
      app
      color="white"
      light
      flat
    >
      <v-app-bar-nav-icon 
        v-if="!sidebarMenu" 
        @click.stop="sidebarMenu = !sidebarMenu" 
      />
      <v-spacer />
      <NotificationButton />
      <v-menu
        offset-y
        class="flex-column"
        left
      >
        <template #activator="{ on, attrs }">
          <v-list>
            <v-list-item
              class="pa-0"
            >
              <v-list-item-avatar 
                class="rounded ma-0"
                size="47"
              >
                <v-img
                  v-bind="attrs"
                  class="elevation-6"
                  alt="ProfileImg"
                  :src="profileImage"
                  v-on="on"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </template>
        <v-list>
          <v-list-item
            to="/account/settings"
          >
            <v-list-item-title>Account Settings</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Sidebar -->
    <NavBarList 
      :sidebar.sync="sidebarMenu"
    />
    <!-- Main Container -->
    <v-container
      class="py-0 fill-height"
      fluid
    >
      <v-row class="fill-height grey">
        <v-col
          md="12"
        />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

// component
import NotificationButton from "@/components/generic/NotificationButton.vue";
import NavBarList from "@/pages/talent/dashboard/NavBarList.vue";

export default {
  components: {
    NotificationButton,
    NavBarList,
  },
  data() {
    return {
      sidebarMenu: true,
      toggleMini: false,
      route: null,
    }
  },
  computed: {
    ...mapGetters([ "profileImage" ]),
    mini() {
      return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
    },
    buttonText() {
      return !this.$vuetify.theme.dark ? 'Go Dark' : 'Go Light'
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      window.location = "/";
    },
  },
}
</script>